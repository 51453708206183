import "@/styles/style.scss"
import React from "react"
import type { GatsbyBrowser } from "gatsby"
import MainLayout from "./src/layout"

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  return (
    <MainLayout>
      {element}
    </MainLayout>
  )
}
